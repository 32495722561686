<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-card title="پرداختی فروشندگان" class="mt-3">
            <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group label="فروشنده" label-for="seller">
                                    <multiselect id="seller" class="form-group" :options="sellers" label="name"
                                        track-by="id" v-model="searchParams.seller" placeholder="" :searchable="true"
                                        :close-on-select="true" :show-labels="false">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group label="وضعیت" label-for="status">
                                    <b-form-select id="status" v-model="searchParams.status">
                                        <b-form-select-option value="1">تسویه شده</b-form-select-option>
                                        <b-form-select-option value="0">تسویه نشده</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
                <template v-slot:cell(pay)="data">
                    <span class="badge badge-primary cursor-pointer"
                        :disabled="data.item.pay_off_status || disabled ? 'true' : 'false'"
                        @click="payment(data.index)">پرداخت</span>
                </template>
                <template v-slot:cell(pay_off_status)="data">
                    <span class="badge badge-success" v-if="data.item.pay_off_status">تسویه شده</span>
                    <span class="badge badge-danger" v-else>تسویه نشده</span>
                </template>
                <template v-slot:cell(invoice_number)="data">
                    {{ data.item.invoice ? data.item.invoice.invoice_number : 0 }}
                </template>

                <template v-slot:cell(seller)="data">
                    {{data.item.seller ? data.item.seller.name + ' ' + data.item.seller.last_name : ''}}
                </template>
                <template v-slot:cell(amount)="data">
                    {{data.item.amount != null ? numberFormat(data.item.amount) : ''}}
                </template>
                <template v-slot:cell(our_amount)="data">
                    {{data.item.our_amount != null ? numberFormat(data.item.our_amount) : ''}}
                </template>
                <template v-slot:cell(seller_amount)="data">
                    {{data.item.seller_amount != null ? numberFormat(data.item.seller_amount) : ''}}
                </template>
                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(details)="data">
                    <button class="btn-sm btn btn-info" @click="showItem(data.item)">
                        <i class="fa fa-info"></i>
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>
        <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
        </pagination>
        <pagination :limit="2" v-else-if="items.data && search" :data="items.data"
            @pagination-change-page="searchItems">
        </pagination>
        <provideModal :orders="edit.orders" />
        <b-modal id="payment" title="پرداخت" hide-footer>
            <form @submit.prevent="paymentItem">
                <b-form-group label="توضیحات" label-for="description">
                    <b-form-textarea id="description" v-model="description" row="4"></b-form-textarea>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import provideModal from './provideModal.vue'
    import mixins from '../mixins/mixins'
    export default {
        components: {
            provideModal
        },
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/provides',
                title: 'پرداختی فروشندگان',
                table_fields: [
                    // {
                    //     key: 'select',
                    //     label: ''
                    // },
                    {
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'invoice_number',
                        label: 'شماره سفارش'
                    },
                    {
                        key: 'seller',
                        label: 'فروشنده'
                    },
                    {
                        key: 'amount',
                        label: 'مبلغ فروش (تومان)'
                    },
                    {
                        key: 'our_amount',
                        label: 'سهم آلبا  (تومان)'
                    },
                    {
                        key: 'seller_amount',
                        label: 'سهم فروشنده (تومان)'
                    },
                    {
                        key: 'pay_off_status',
                        label: 'وضعیت پرداخت'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                    {
                        key: 'details',
                        label: 'جزئیات'
                    }
                ],
                status: 'pending',
                description: '',
                sellers: []
            }
        },
        mounted() {
            this.loadItems()
            this.loadSellers()
            this.searchParams = {
                invoice_number: '',
                status: '',
            }
        },
        methods: {
            payment(index) {
                this.disabled = true
                let item = this.items.data.data[index]
            },
            loadSellers() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/sellers?all=true')
                    .then(response => {
                        this.sellers = response.data.data.filter(item => item.name = item.name + item.last_name +
                            ' - شرکت ' + item.company_name)
                    })
            },
            showItem(item) {
                this.edit = item
                this.$root.$emit('bv::show::modal', 'provide-modal')
            },
            searchItems(page = 1) {
                this.disabled = true
                this.search = true
                this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
                        params: {
                            seller_id: this.searchParams.seller != null ? this.searchParams.seller.id : '',
                            pay_off: this.searchParams.status,
                        }
                    })
                    .then(response => {
                        this.search = true
                        this.items = response.data
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
        }
    }
</script>