<template>
    <b-modal id="provide-modal" title="اقلام" hide-footer size="lg">
        <div v-if="orders">
            <b-table responsive striped hover :fields="fields" :items="orders">
                <template v-slot:cell(product)="data">
                    {{ data.item.variety ? data.item.variety.fullTitle :'' }}
                </template>
                <template v-slot:cell(price)="data">
                    {{ (data.item.pivot ? data.item.pivot.price : 0 ) | price }}
                </template>
                <template v-slot:cell(available_count)="data">
                    {{ data.item.pivot ? data.item.pivot.available_count : 0  }}
                </template>
                <template v-slot:cell(show)="data">
                    <router-link
                        :to="{name:'products.edit.index' , params:{product: 'edit' , id:data.item.variety.product_id}}"
                        target="_blank" class="btn btn-info">
                        <i class="fa fa-eye"></i>
                    </router-link>
                </template>
            </b-table>
        </div>
    </b-modal>
</template>
<script>
    export default {
        props: [
            'orders'
        ],
        data() {
            return {
                fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'product',
                        label: 'محصول'
                    },
                    {
                        key: 'price',
                        label: 'قیمت فروشنده (تومان)'
                    },
                    {
                        key: 'available_count',
                        label: 'تعداد ارسال'
                    },
                    {
                        key: 'show',
                        label: 'مشاهده'
                    },
                ]
            }
        }
    }
</script>